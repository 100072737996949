<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container
      style=""
    >
      <v-row
        class="ma-0 pa-1"
        style="position:absolute;top:0px;z-index:10;"
      >
        <v-col
          class="pa-0"
          cols="7"
        >
          <v-dialog
            ref="dialog"
            v-model="modal"
            class="date-picker"
            :return-value.sync="orderDate"
            :width="datePickerWidth"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="orderDate"
                label="작업지시일"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                autocomplete="off"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="orderDate"
              locale="ko-ko"
              @input="onDateInput"
            />
          </v-dialog>
        </v-col>
      </v-row>
      <div class="work-order-grid">
        <dx-data-grid
          ref="refWorkOrderGrid"
          :data-source="workOrderInfoList"
          :show-borders="true"
          :show-row-lines="false"
          :show-column-lines="true"
          :hover-state-enabled="true"
          :allow-column-resizing="true"
          column-resizing-mode="widget"
          :allow-column-reordering="true"
          :height="gridHeight"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          :auto-navigate-to-focused-row="false"
          key-expr="seqId"
          @focused-row-changed="onSelectionChanged"
          @toolbar-preparing="onToolbarPreparing"
        >
          <DxColumnFixing :enabled="true" />
          <DxColumn
            :allow-editing="false"
            :fixed="false"
            caption="작업번호"
            data-field="seqId"
            :visible="false"
            :min-width="80"
          />
          <DxColumn
            :allow-editing="true"
            caption="품목유형"
            data-field="품목유형"
            :visible="true"
            sort-order="asc"
            :min-width="80"
          >
            <DxLookup
              :data-source="goodsLookup"
              display-expr="코드명"
              value-expr="코드"
            />
          </DxColumn>
          <DxColumn
            :allow-editing="true"
            :fixed="false"
            caption="품번"
            data-field="품번"
            sort-order="asc"
            :min-width="160"
          />
          <DxColumn
            :allow-editing="true"
            caption="계획"
            data-field="수량"
            :visible="true"
            format="#,##0"
            :min-width="60"
          />
          <DxColumn
            :allow-editing="true"
            caption="시작시간"
            data-field="생산시작시간"
            data-type="datetime"
            format="HH:mm"
            :min-width="80"
          />
          <DxColumn
            :allow-editing="true"
            caption="종료시간"
            data-field="생산종료시간"
            data-type="datetime"
            format="HH:mm"
            :min-width="80"
          />
          <DxColumn
            :allow-editing="true"
            caption="라인명"
            data-field="라인명"
            :visible="true"
            :min-width="80"
          />
          <DxColumn
            :allow-editing="true"
            caption="품명"
            data-field="품명"
            :visible="true"
            :min-width="120"
          />
          <DxSummary>
            <DxTotalItem
              value-format="#,##0"
              column="품번"
              display-format="{0} (건)"
            />
          </DxSummary>
          <dx-paging :enabled="false" />
          <dx-scrolling
            mode="virtual"
          />
          <dx-sorting mode="multiple" />
        </dx-data-grid>
      </div>
    </v-container>
    <div
      class="left-draw-wrap"
    >
      <v-app-bar
        id="left-draw-app-bar"
        dark
        height="50px"
      >
        <v-icon
          large
          @click="showLeftDrawView(false)"
        >
          mdi-chevron-left
        </v-icon>
        <v-toolbar-title style="margin-left:10px;">
          {{ ouputRegTitle }}
        </v-toolbar-title>
      </v-app-bar>
      <div
        class="left-draw-content"
      >
        <v-container>
          <v-row class="pb-0">
            <v-col
              class="pb-0 pt-0"
              cols="6"
            >
              <v-autocomplete
                id="input-line-code"
                v-model="selectedLineCode"
                :items="lineList"
                label="생산라인"
                item-text="라인명"
                item-value="라인코드"
                auto-select-first
                :disabled="isEditMode || copyOutput"
                @change="onLineCodeChange"
              />
            </v-col>
            <v-col
              class="pb-0 pt-0"
              cols="6"
            >
              <v-autocomplete
                id="input-person"
                v-model="selectedPerson"
                :items="employeeList"
                label="작업자"
                :item-text="getPerson"
                item-value="사번"
                auto-select-first
                @change="onPersonChange"
              />
            </v-col>
          </v-row>
          <v-row class="pb-0">
            <v-col
              class="pb-0 pt-0"
              cols="6"
            >
              <v-autocomplete
                id="input-pcode"
                v-model="selectedPCode"
                :items="productionInfoList"
                label="품번"
                :item-text="getProduction"
                item-value="품번"
                auto-select-first
                :disabled="isEditMode"
                @change="onPCodeChange"
              />
            </v-col>
            <v-col
              class="pb-0 pt-0"
              cols="6"
            >
              <v-autocomplete
                id="input-type"
                v-model="selectedType"
                :items="outputStatusLookup"
                label="생산구분"
                item-text="코드명"
                item-value="코드"
                auto-select-first
                :disabled="isEditMode"
                @change="onTypeChange"
              />
            </v-col>
          </v-row>
          <v-row class="pb-0">
            <v-col
              class="pb-0 pt-0"
              cols="6"
            >
              <v-dialog
                ref="refStartDateDialog"
                v-model="startDateModal"
                class="date-picker"
                :return-value.sync="startDate"
                :width="datePickerWidth"
                :disabled="isEditMode || copyOutput"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    label="작업시작일"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    autocomplete="off"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-if="startDateModal"
                  v-model="startDate"
                  locale="ko-ko"
                  @input="$refs.refStartDateDialog.save(startDate)"
                />
              </v-dialog>
            </v-col>
            <v-col
              class="pb-0 pt-0"
              cols="6"
            >
              <v-dialog
                ref="refStartTime"
                v-model="startTimeModal"
                :close-on-content-click="false"
                :return-value.sync="startTime"
                :width="datePickerWidth"
                :disabled="isEditMode || copyOutput"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startTime"
                    label="시작시간"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    autocomplete="off"
                    v-on="on"
                  />
                </template>
                <v-time-picker
                  v-if="startTimeModal"
                  v-model="startTime"
                  format="24hr"
                  full-width
                  @click:minute="$refs.refStartTime.save(startTime)"
                />
              </v-dialog>
            </v-col>
          </v-row>
          <v-row class="pb-0">
            <v-col
              class="pb-0 pt-0"
              cols="6"
            >
              <v-dialog
                ref="refEndDateDialog"
                v-model="endDateModal"
                class="date-picker"
                :return-value.sync="endDate"
                :width="datePickerWidth"
                :disabled="isEditMode"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDate"
                    label="작업종료일"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    autocomplete="off"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-if="endDateModal"
                  v-model="endDate"
                  locale="ko-ko"
                  @input="$refs.refEndDateDialog.save(endDate)"
                />
              </v-dialog>
            </v-col>
            <v-col
              class="pb-0 pt-0"
              cols="6"
            >
              <v-dialog
                ref="refEndTime"
                v-model="endTimeModal"
                :close-on-content-click="false"
                :return-value.sync="endTime"
                :width="datePickerWidth"
                :disabled="isEditMode"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endTime"
                    label="종료시간"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    autocomplete="off"
                    v-on="on"
                  />
                </template>
                <v-time-picker
                  v-if="endTimeModal"
                  v-model="endTime"
                  format="24hr"
                  full-width
                  @click:minute="$refs.refEndTime.save(endTime)"
                />
              </v-dialog>
            </v-col>
          </v-row>
          <v-row class="pb-0">
            <v-col
              class="pb-0 pt-0"
              cols="6"
            >
              <v-text-field
                id="input-qty"
                v-model="qty"
                autocomplete="off"
                label="양품수량"
                placeholder="수량"
                type="number"
                clearable
                @keyup.enter.prevent="onEnterQty"
              />
            </v-col>
            <v-col
              class="pb-0 pt-0"
              cols="6"
            >
              <v-text-field
                id="input-qty"
                v-model="badQty"
                autocomplete="off"
                label="불량수량"
                placeholder="수량"
                type="number"
                clearable
                @keyup.enter.prevent="onEnterBadQty"
              />
            </v-col>
          </v-row>
          <v-row class="pb-0">
            <v-col
              class="pb-0 pt-0"
              :cols="isEditMode ? 6: 12"
            >
              <v-btn
                width="100%"
                color="primary"
                @click="onSaveOutput"
              >
                생산실적 저장
              </v-btn>
            </v-col>
            <v-col
              v-if="isEditMode"
              class="pb-0 pt-0"
              cols="6"
            >
              <v-btn
                class="white--text"
                width="100%"
                color="success"
                @click="onDeleteOutput"
              >
                생산실적 삭제
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div
        class="work-output-grid"
        style="margin-top:0px;"
      >
        <v-container>
          <dx-data-grid
            ref="refWorkoutputGrid"
            :data-source="workOutputInfoList"
            :show-borders="true"
            :show-row-lines="false"
            :show-column-lines="true"
            :hover-state-enabled="true"
            :allow-column-resizing="true"
            column-resizing-mode="widget"
            :allow-column-reordering="true"
            :height="gridSubHeight"
            :row-alternation-enabled="true"
            :focused-row-enabled="true"
            :auto-navigate-to-focused-row="false"
            key-expr="seqId"
            :focused-row-key.sync="focusedRowKey"
            @focused-row-changed="onSelectionChangedSub"
            @row-click="onRowClickSub"
          >
            <DxColumnFixing :enabled="true" />
            <DxColumn
              width="40"
              :buttons="editSubButtons"
              type="buttons"
            />
            <DxColumn
              :allow-editing="true"
              :fixed="false"
              caption="품번"
              data-field="품번"
              :min-width="120"
            />
            <DxColumn
              :allow-editing="true"
              caption="품명"
              data-field="품명"
              :visible="false"
              :min-width="120"
            />
            <DxColumn
              :allow-editing="true"
              caption="품목유형"
              data-field="품목유형"
              :visible="true"
              :min-width="80"
            >
              <DxLookup
                :data-source="goodsLookup"
                display-expr="코드명"
                value-expr="코드"
              />
            </DxColumn>
            <DxColumn
              :allow-editing="true"
              caption="생산구분"
              data-field="생산구분"
              :visible="true"
              :min-width="80"
            >
              <DxLookup
                :data-source="outputStatusLookup"
                display-expr="코드명"
                value-expr="코드"
              />
            </DxColumn>
            <DxColumn
              :allow-editing="true"
              caption="라인명"
              data-field="라인명"
              :visible="true"
              sort-order="asc"
              :min-width="80"
            />
            <DxColumn
              :allow-editing="true"
              caption="시작시간"
              data-field="시작시간"
              data-type="datetime"
              format="yyyy-MM-dd HH:mm"
              :min-width="120"
              sort-order="asc"
            />
            <DxColumn
              :allow-editing="true"
              caption="종료시간"
              data-field="종료시간"
              data-type="datetime"
              format="yyyy-MM-dd HH:mm"
              :min-width="120"
            />
            <DxColumn
              :allow-editing="true"
              caption="양품"
              data-field="양품"
              :visible="true"
              format="#,##0"
              width="60"
            />
            <DxColumn
              :allow-editing="true"
              caption="불량"
              data-field="불량"
              :visible="true"
              format="#,##0"
              :min-width="60"
            />
            <DxColumn
              :allow-editing="true"
              caption="생산(반)"
              data-field="부서명"
              :visible="false"
              :min-width="80"
            />
            <DxColumn
              :allow-editing="true"
              caption="작업자"
              data-field="성명"
              :visible="true"
              :min-width="80"
            />
            <DxSummary>
              <DxTotalItem
                value-format="#,##0"
                column="품번"
                display-format="{0} (건)"
              />
            </DxSummary>
            <dx-paging :enabled="false" />
            <dx-scrolling
              mode="virtual"
            />
            <dx-sorting mode="multiple" />
          </dx-data-grid>
        </v-container>
      </div>
    </div>
    <DxPopupGen
      :visible.sync="popupWorkTime"
      :drag-enabled="true"
      :close-on-outside-click="false"
      position="top"
      :show-title="true"
      :show-close-button="true"
      :width="htmlWdth-20"
      :height="310"
      title="작업시간 선택"
    >
      <div style="position:relative;">
        <div class="dx-field">
          <div class="dx-field-label">
            근무조건:
          </div>
          <div class="dx-field-value">
            <DxRadioGroup
              :items="workType"
              :value="workType[0]"
              @value-changed="OnChangeSelectionWorkType"
            />
          </div>
        </div>
        <div
          v-if="selectedWorkType === 1"
          class="dx-field"
        >
          <div class="dx-field-label">
            근무시간대:
          </div>
          <div class="dx-field-value">
            <DxRadioGroup
              :items="workTime2"
              :value.sync="selectedWorkTime2"
            />
          </div>
        </div>
        <div
          v-if="selectedWorkType === 2"
          class="dx-field"
        >
          <div class="dx-field-label">
            근무시간대:
          </div>
          <div class="dx-field-value">
            <DxRadioGroup
              :items="workTime3"
              :value.sync="selectedWorkTime3"
            />
          </div>
        </div>
        <div style="margin-top:10px;text-align:center">
          <DxButton
            text="확인"
            width="168"
            type="success"
            @click="onWorkTypeConfirm"
          />
        </div>
      </div>
    </DxPopupGen>
    <loading
      :active.sync="isLoading"
      color="rgb(2, 110, 156)"
      loader="bars"
    />
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
// import HttpService from '../../share/service/HttpService'
// import AesCrypto from '../../share/service/AesCrypto'
import DxDataGrid, {
  DxScrolling, DxColumnFixing, DxTotalItem, DxLookup,
  DxSummary, DxColumn, DxPaging, DxSorting
} from 'devextreme-vue/data-grid'
import DxRadioGroup from 'devextreme-vue/radio-group'
import { DxPopup as DxPopupGen } from 'devextreme-vue/popup'
import { DxButton } from 'devextreme-vue'
import axios from 'axios'

export default {
  name: 'EmptView',
  components: {
    Loading,
    DxDataGrid,
    DxScrolling,
    DxColumnFixing,
    DxTotalItem,
    DxLookup,
    DxSummary,
    DxColumn,
    DxPaging,
    DxSorting,
    DxRadioGroup,
    DxPopupGen,
    DxButton
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      workOrderInfoList: null,
      orderDate: null,
      gridHeight: null,
      selectedRowOrder: null,
      modal: false,
      htmlWdth: null,
      popupWorkTime: false,
      workType: [ConstDef.근무형태[0].코드명, ConstDef.근무형태[1].코드명, ConstDef.근무형태[2].코드명],
      selectedWorkType: 0,
      workTime2: ['주간', '야간'],
      selectedWorkTime2: null,
      workTime3: ['주간조', '중간조', '야간조'],
      selectedWorkTime3: null,
      ouputRegTitle: null,
      copyOutput: null,
      isFromOrderNo: null,
      // reg variable
      lineList: [],
      selectedLineCode: null,
      selectedPerson: null,
      employeeList: [],
      selectedPCode: null,
      productionInfoList: [],
      outputStatusLookup: [],
      selectedType: null,
      startDateModal: false,
      startDate: null,
      startTimeModal: false,
      startTime: null,
      endDateModal: false,
      endTimeModal: false,
      endDate: null,
      endTime: null,
      badQty: null,
      qty: null,
      datePickerWidth: '350px',
      isEditMode: false,
      // workout result grid
      gridSubHeight: 300,
      workOutputInfoList: null,
      focusedRowKey: null,
      selectedRowOutput: null,
      editSubButtons: [
        {
          hint: '선택라인 추가 등록',
          icon: 'copy',
          visible: 'true',
          onClick: this.onClickCopyOutput
        }]
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
      return
    }
    this.datePickerWidth = this.getDatePickerWidth()
    this.getDateOrderDate()
    this.goodsLookup = this.$store.getters.getBaseDataByType(ConstDef.품목유형)
    this.outputStatusLookup = this.$store.getters.getBaseDataByType(ConstDef.생산진행상태)
    this.refreshWorkOrder()
    this.refreshProduction()
    this.refreshLine()
  },
  mounted () {
    this.gridHeight = (this.$Q('html').height() - 76)
    this.htmlWdth = this.$Q('html').width()
    window.addEventListener('resize', this.handleResize)
    this.$Q('.left-draw-wrap').css('left', `${this.htmlWdth}px`)
    this.$Q('.left-draw-wrap').css('height', `${this.$Q('html').height()}px`)
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  updated () {
  },
  methods: {
    getDatePickerWidth () {
      return `${AppLib.getDatePickerWidth(this.$store)}px`
    },
    setDataGridHeight () {
      const drawContentHeight = this.$Q('.left-draw-content').height()
      this.gridSubHeight = this.$Q('html').height() - drawContentHeight - 80
    },
    handleResize (event) {
      this.htmlWdth = this.$Q('html').width()
      if (this.$Q('.left-draw-wrap').css('left') !== '0px') {
        this.$Q('.left-draw-wrap').css('left', `${this.htmlWdth}px`)
      }
      this.$Q('.left-draw-wrap').css('height', `${this.$Q('html').height()}px`)
      this.gridHeight = (this.$Q('html').height() - 76)
      const drawContentHeight = this.$Q('.left-draw-content').height()
      if (AppLib.isScreenVertical(this.$Q)) {
        this.gridSubHeight = this.$Q('html').height() - drawContentHeight - 80
      } else {
        this.gridSubHeight = drawContentHeight
      }
    },
    getDateOrderDate () {
      // const startTime = this.$_.findWhere(this.$store.getters.getSysParam, { key: '주간생산시작시간' }).value
      const time = AppLib.getNowTime()
      if (time >= '00:00' && time < '13:00') {
        this.orderDate = AppLib.calcDateFromToday(-1)
      } else {
        this.orderDate = AppLib.getToday()
      }
      this.orderDate = AppLib.calcDateFromToday(-1)
    },
    getPermission () {
      const worker = this.$_.findWhere(ConstDef.USER_PERMISSION, { groupName: '생산현장관리자' }).permission |
        this.$_.findWhere(ConstDef.USER_PERMISSION, { groupName: '생산현장직' }).permission
      return worker & this.userInfo.permission ? this.userInfo.division : 'MANAGER'
    },
    onDateInput (e) {
      this.$refs.dialog.save(this.orderDate)
      this.refreshWorkOrder()
    },
    onSelectionChanged (e) {
      if (e != null && e.row === undefined) {
        return
      }
      if (e !== null) {
        this.selectedRowOrder = e.row.data
      }
    },
    onToolbarPreparing   (e) {
      e.toolbarOptions.items.unshift(
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'always',
          options: {
            icon: 'rowproperties',
            text: '선택 작업지시 실적등록',
            focusStateEnabled: true,
            onClick: (args) => {
              if (this.selectedRowOrder === null) {
                this.$snotify.error('해당 행을 선책하여 주세요.')
                return
              }
              this.onRegOutputByOrder(this.selectedRowOrder)
            }
          }
        },
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'always',
          options: {
            icon: 'download',
            text: '선택 작업지시 중단등록',
            focusStateEnabled: true,
            onClick: (args) => {
              if (this.selectedRowOrder === null) {
                this.$snotify.error('해당 행을 선책하여 주세요.')
                return
              }
              this.onRegLineStopByOrder(this.selectedRowOrder)
            }
          }
        },
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'always',
          options: {
            icon: 'plus',
            text: '실적/중단 등록',
            focusStateEnabled: true,
            onClick: (args) => {
              this.onRegOutput()
            }
          }
        }
      )
    },
    refreshWorkOrder () {
      this.isLoading = true
      const permission = this.getPermission()
      const order = this.$_sp.runNoEncodeFindProc('spFindAllWorkOrderByDateAndPermission', { 생산기준일: this.orderDate, division: permission })
      const output = this.$_sp.runNoEncodeFindProc('spFindAllWorkOutputByDateAndPermission', { 생산기준일: this.orderDate, division: permission })
      axios.all([order, output])
        .then(axios.spread((...reponse) => {
          this.isLoading = false
          this.workOrderInfoList = this.$_sp.MakeModel(reponse[0])
          this.workOutputInfoList = this.$_sp.MakeModel(reponse[1])
        }))
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    // POPUOP ******************
    OnChangeSelectionWorkType (e) {
      console.log(e)
      this.selectedWorkType = this.$_.indexOf(this.workType, e.value)
    },
    onWorkTypeConfirm () {
      this.popupWorkTime = false
      this.showLeftDrawView(true)
    },
    // ************** 생산실적 draw view **********************
    initNewRow () {
      const overTime = parseInt(this.$_.findWhere(this.$store.getters.getSysParam, { key: '잔업시간' }).value)
      let startTime = null
      let endTime = null
      if (this.copyOutput === false) {
        switch (this.selectedWorkType) {
          case 0:
            startTime = `${this.orderDate} ${this.$_.findWhere(this.$store.getters.getSysParam, { key: '주간생산시작시간' }).value}`
            endTime = AppLib.getTimestampFromString(`${startTime}:00`)
            endTime = AppLib.formatDateTimeFromTimeStamp(endTime + (9 * 3600 * 1000 + overTime * 3600 * 1000))
            break
          case 1: // 2교대
            if (this.selectedWorkTime2 === '주간') {
              startTime = `${this.orderDate} ${this.$_.findWhere(this.$store.getters.getSysParam, { key: '주간생산시작시간' }).value}`
              endTime = AppLib.getTimestampFromString(`${startTime}:00`)
              endTime = AppLib.formatDateTimeFromTimeStamp(endTime + (9 * 3600 * 1000 + overTime * 3600 * 1000))
            } else {
              startTime = `${this.orderDate} ${this.$_.findWhere(this.$store.getters.getSysParam, { key: '야간생산시작시간' }).value}`
              endTime = AppLib.getTimestampFromString(`${startTime}:00`)
              endTime = AppLib.formatDateTimeFromTimeStamp(endTime + (9 * 3600 * 1000 + overTime * 3600 * 1000))
            }
            break
          case 2: // 3교대
            if (this.selectedWorkTime2 === '주간조') {
              startTime = `${this.orderDate} ${this.$_.findWhere(this.$store.getters.getSysParam, { key: '주간생산시작시간' }).value}`
              endTime = AppLib.getTimestampFromString(`${startTime}:00`)
              endTime = AppLib.formatDateTimeFromTimeStamp(endTime + (8 * 3600 * 1000))
            } else if (this.selectedWorkTime2 === '중간조') {
              startTime = `${this.orderDate} ${this.$_.findWhere(this.$store.getters.getSysParam, { key: '주간생산시작시간' }).value}`
              endTime = AppLib.getTimestampFromString(`${startTime}:00`)
              startTime = AppLib.formatDateTimeFromTimeStamp(endTime + (8 * 3600 * 1000))
              endTime = AppLib.getTimestampFromString(`${startTime}:00`)
              endTime = AppLib.formatDateTimeFromTimeStamp(endTime + (8 * 3600 * 1000))
            } else {
              startTime = `${this.orderDate} ${this.$_.findWhere(this.$store.getters.getSysParam, { key: '주간생산시작시간' }).value}`
              endTime = AppLib.getTimestampFromString(`${startTime}:00`)
              startTime = AppLib.formatDateTimeFromTimeStamp(endTime + (16 * 3600 * 1000))
              endTime = AppLib.getTimestampFromString(`${startTime}:00`)
              endTime = AppLib.formatDateTimeFromTimeStamp(endTime + (8 * 3600 * 1000))
            }
            break
        }
        this.startDate = startTime.substring(0, 10)
        this.startTime = startTime.substring(11, 16)
        this.endDate = endTime.substring(0, 10)
        this.endTime = endTime.substring(11, 16)
        this.badQty = 0
        if (this.isFromOrderNo) {
          this.selectedPCode = this.selectedRowOrder.품번
          this.selectedLineCode = this.selectedRowOrder.라인코드
          this.refreshEmployee(this.selectedRowOrder.라인코드)
        }
      } else {
        this.badQty = 0
        this.startDate = this.endDate = AppLib.formatDateTimeFromTimeStamp(this.selectedRowOutput.종료시간).substring(0, 10)
        this.startTime = this.endTime = AppLib.formatDateTimeFromTimeStamp(this.selectedRowOutput.종료시간).substring(11, 16)
        this.selectedLineCode = this.selectedRowOutput.라인코드
        this.selectedPerson = this.selectedRowOutput.작업자사번
        this.refreshEmployee(this.selectedRowOutput.라인코드)
      }
    },
    getProduction (row) {
      return `${row.품번} (${row.품명})`
    },
    getPerson (row) {
      return `${row.성명} (${row.부서명})`
    },
    onEnterBadQty (e) {

    },
    onEnterQty (e) {

    },
    onLineCodeChange (e) {
      this.refreshEmployee(this.selectedLineCode)
    },
    onPCodeChange (e) {

    },
    onPersonChange (e) {

    },
    onRegOutputByOrder (selectedOrder) {
      this.initInputData()
      this.ouputRegTitle = '생산실적 등록'
      this.copyOutput = false
      this.isFromOrderNo = true
      this.selectedLineCode = selectedOrder.라인코드
      this.initNewRow()
      this.popupWorkTime = true
    },
    onRegLineStopByOrder (selectedOrder) {
      this.initInputData()
      this.ouputRegTitle = '생산중단 등록'
      this.copyOutput = false
      this.isFromOrderNo = true
      this.selectedLineCode = selectedOrder.라인코드
      this.initNewRow()
      this.popupWorkTime = true
    },
    onRegOutput () {
      this.initInputData()
      this.ouputRegTitle = '생산실적/중단 등록'
      this.initNewRow()
      this.popupWorkTime = true
    },
    onDeleteOutput (e) {
      this.$snotify.confirm(`해당라인의 시작시간 ${AppLib.formatDateTimeFromTimeStamp(this.selectedRowOutput.시작시간)}보다 큰 행은 모두 삭제 됩니다.`, '', {
        timeout: 10000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {
            text: '삭제',
            action: (toast) => {
              this.$snotify.remove(toast.id)
              this.isLoading = true
              this.$_sp.runUpdateProc('spDeleteOutputByDateAndLineCodeAndStartTime',
                [{ 생산기준일: this.selectedRowOutput.생산기준일, 라인코드: this.selectedRowOutput.라인코드, 시작시간: this.selectedRowOutput.시작시간 }])
                .then((data) => {
                  this.isLoading = false
                  this.$snotify.info('삭제 되었습니다.')
                  this.initInputData()
                  this.refreshWorkOutput()
                })
                .catch(error => {
                  this.isLoading = false
                  if (error.response.status === ConstDef.GENERRAL_ERROR) {
                    this.$snotify.error(error.response.data.message)
                  } else {
                    this.$snotify.error(`에러코드 : ${error.response.status}`)
                  }
                })
            }
          },
          {
            text: '취소',
            action: (toast) => { this.$snotify.remove(toast.id) }
          }
        ]
      })
    },
    onSaveOutput (e) {
      if (this.isValidate() === false) {
        return
      }
      if (this.isEditMode) {
        this.selectedRowOutput.시작시간 = AppLib.getTimestampFromString(`${this.startDate} ${this.startTime}:00`)
        this.selectedRowOutput.종료시간 = AppLib.getTimestampFromString(`${this.endDate} ${this.endTime}:00`)
        this.selectedRowOutput.양품 = this.qty
        this.selectedRowOutput.불량 = this.badQty
        this.selectedRowOutput.작업자사번 = this.selectedPerson
        this.selectedRowOutput.생산반 = this.$_.findWhere(this.employeeList, { 사번: this.selectedPerson }).부서코드
        this.selectedRowOutput.생산구분 = this.selectedType
        this.selectedRowOutput.updateid = this.userInfo.userName
        if (this.checkStartEndTime(this.selectedRowOutput) === false) {
          return
        }
        this.isLoading = true
        this.$_sp.runUpdateSqlProc('생산실적', ['seqId'], ['품명', '라인명', '부서명', '품목유형', '성명'], [this.selectedRowOutput])
          .then((data) => {
            this.isLoading = false
            this.initInputData()
            this.$snotify.info('저장 되었습니다.')
          })
          .catch(error => {
            this.isLoading = false
            if (error.response.status === ConstDef.GENERRAL_ERROR) {
              this.$snotify.error(error.response.data.message)
            } else {
              this.$snotify.error(`에러코드 : ${error.response.status}`)
            }
          })
      } else {
        const temp = {
          seqId: AppLib.nowTimestamp(),
          생산기준일: this.orderDate,
          품번: this.selectedPCode,
          라인코드: this.selectedLineCode,
          시작시간: AppLib.getTimestampFromString(`${this.startDate} ${this.startTime}:00`),
          종료시간: AppLib.getTimestampFromString(`${this.endDate} ${this.endTime}:00`),
          양품: this.qty,
          불량: this.badQty,
          작업자사번: this.selectedPerson,
          생산반: this.$_.findWhere(this.employeeList, { 사번: this.selectedPerson }).부서코드,
          생산구분: this.selectedType,
          작업지시번호: this.isFromOrderNo ? this.selectedRowOrder.작업지시번호 : null,
          createid: this.userInfo.userName,
          updateid: this.userInfo.userName,
          createtime: AppLib.getNow(),
          품목유형: this.$_.findWhere(this.productionInfoList, { 품번: this.selectedPCode }).품목유형,
          라인명: this.$_.findWhere(this.lineList, { 라인코드: this.selectedLineCode }).라인명
        }
        if (this.checkStartEndTime(temp) === false) {
          return
        }
        this.isLoading = true
        this.$_sp.runInsertAndFindSqlProc('생산실적', ['생산기준일', '품번', 'createtime'], ['seqId', '품목유형', '라인명'], temp)
          .then((data) => {
            this.isLoading = false
            this.focusedRowKey = temp.seqId = this.$_sp.MakeModel(data)[0].seqId
            this.workOutputInfoList.push(temp)
            this.initInputData()
            this.$snotify.info('저장 되었습니다.')
          })
          .catch(error => {
            this.isLoading = false
            if (error.response.status === ConstDef.GENERRAL_ERROR) {
              this.$snotify.error(error.response.data.message)
            } else {
              this.$snotify.error(`에러코드 : ${error.response.status}`)
            }
          })
      }
    },
    initInputData () {
      this.selectedLineCode = null
      this.selectedPerson = null
      this.selectedPCode = null
      this.selectedType = null
      this.startDate = null
      this.startTime = null
      this.endDate = null
      this.endTime = null
      this.qty = null
      this.badQty = 0
      this.copyOutput = false
      this.isFromOrderNo = false
      this.isEditMode = false
    },
    isValidate () {
      if (this.selectedLineCode === null) {
        this.$snotify.error('생산라인을 선택하여 주세요.')
        return false
      }
      if (this.selectedPerson === null) {
        this.$snotify.error('작업자를 선택하여 주세요.')
        return false
      }
      if (this.selectedPCode === null) {
        this.$snotify.error('품번을 선택하여 주세요.')
        return false
      }
      if (this.selectedType === null) {
        this.$snotify.error('생산구분을 선택하여 주세요.')
        return false
      }
      if (this.startDate === null || this.startTime === null) {
        this.$snotify.error('작업시작시간을 입력하여 주세요.')
        return false
      }
      if (this.endDate === null || this.endTime === null) {
        this.$snotify.error('작업종료시간을 입력하여 주세요.')
        return false
      }
      if (this.qty === undefined || this.qty === null || !AppLib.isNumeric(this.qty)) {
        this.$snotify.error('양품 생산수량을 입력하여 주세요.')
        return false
      }
      if (this.badQty === null) {
        this.$snotify.error('불량수량을 입력하여 주세요.')
        return false
      }
      return true
    },
    onTypeChange (e) {

    },
    refreshLine () {
      const permission = this.getPermission()
      this.$_sp.runNoEncodeFindProc('spFindAllLineByPermission', { division: permission })
        .then((data) => {
          this.lineList = this.$_sp.MakeModel(data)
        })
    },
    refreshEmployee (lineCode) {
      console.log('refreshEmployee')
      this.$_sp.runNoEncodeFindProc('spFindAllEmployeeBylineCode', { 라인코드: lineCode })
        .then((data) => {
          this.employeeList = this.$_sp.MakeModel(data)
          if (this.isEditMode) {
            this.selectedPerson = this.selectedRowOutput.작업자사번
          }
        })
    },
    refreshProduction () {
      this.$_sp.runNoEncodeFindProc('spFindAllProductByType', { 품목유형: 'ALL' })
        .then((data) => {
          this.productionInfoList = this.$_sp.MakeModel(data)
        })
    },
    showLeftDrawView (isShow) {
      const vm = this
      if (isShow) {
        this.$Q('.left-draw-wrap').animate({
          left: '0'
        }, 800, function () {
          vm.$Q('#left-draw-app-bar').css('position', 'fixed')
          vm.$Q('.left-draw-content').css('padding-top', '50px')
          vm.setDataGridHeight()
        })
      } else {
        this.$Q('#left-draw-app-bar').css('position', 'relative')
        this.$Q('.left-draw-content').css('padding-top', '0px')
        this.$Q('.left-draw-wrap').animate({
          left: `${this.htmlWdth}px`
        }, 500)
      }
    },
    // ********* output grid ***********************
    checkStartEndTime (row) {
      if ((row.종료시간 !== null && row.시작시간 !== undefined) && row.종료시간 <= row.시작시간) {
        this.$snotify.error('종료시간은 시작시간보다 커야 합니다.')
        return false
      }
      if (this.isEditMode) return

      let where = this.$_.where(this.workOutputInfoList, { 라인코드: row.라인코드, 생산반: row.생산반 })
      console.log(where)
      if (where.length > 0) {
        where = this.$_.sortBy(where, '시작시간')
        if (where[where.length - 1].종료시간 !== row.시작시간) {
          this.$snotify.error('시작시간이 마지막 입력한 라인의 종료시간과 동일한 시간으로 입력하여야 합니다.')
          return false
        }
      }
      return true
    },
    onClickCopyOutput (e) {
      console.log(e.row.data)
      this.initInputData()
      this.selectedRowOutput = e.row.data
      this.copyOutput = true
      this.isFromOrderNo = false
      this.initNewRow()
    },
    onRowClickSub (row) {
      console.log('onRowClickSub')
      this.isEditMode = true
      this.setRow(row.data)
      this.refreshEmployee(row.data.라인코드)
    },
    onSelectionChangedSub (e) {
      if (e != null && e.row === undefined) {
        return
      }
      if (e !== null) {
        this.selectedRowOutput = e.row.data
      }
    },
    refreshWorkOutput () {
      const permission = this.getPermission()
      this.isLoading = true
      this.$_sp.runNoEncodeFindProc('spFindAllWorkOutputByDateAndPermission', { 생산기준일: this.orderDate, division: permission })
        .then((data) => {
          this.isLoading = false
          this.workOutputInfoList = this.$_sp.MakeModel(data)
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    setRow (row) {
      this.selectedPCode = row.품번
      this.selectedLineCode = row.라인코드
      this.startDate = AppLib.formatDateTimeFromTimeStamp(row.시작시간).substring(0, 10)
      this.startTime = AppLib.formatDateTimeFromTimeStamp(row.시작시간).substring(11, 16)
      this.endDate = AppLib.formatDateTimeFromTimeStamp(row.종료시간).substring(0, 10)
      this.endTime = AppLib.formatDateTimeFromTimeStamp(row.종료시간).substring(11, 16)

      this.qty = row.양품
      this.badQty = row.불량
      this.selectedPerson = row.작업자사번
      this.selectedType = row.생산구분
    },
    showEditArea (isShow) {
      if (isShow) {
        this.$Q('.left-draw-content').show()
      } else {
        this.$Q('.left-draw-content').hide()
      }
    }
  }
}
</script>

<style lang="scss">
.work-output-grid {
  background-color: black;
}
</style>
